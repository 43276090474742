// @flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'

import './legal.scss'

type Props = {}

export default class TokensPage extends Component<Props> {
  render() {
    return (
      <Layout className="legal-page">
        <Helmet title="Terms of Use - Mainframe - Decentralized Application Platform" />
        <div className="container">
          <h1>Website Terms of Use</h1>
          <p>
            These Terms of Use (this &ldquo;Agreement&rdquo;) constitutes a
            legally binding contract between Mainframe Group Inc., a Delaware
            corporation (&ldquo;Company,&rdquo; &ldquo;we,&rdquo;
            &ldquo;us,&rdquo; &ldquo;our&rdquo;) and you with respect to your
            use of our website at mainframe.com (collectively, the
            &ldquo;Site&rdquo;). It is important that you carefully read and
            understand the terms and conditions of this Agreement. {'"'}THESE
            TERMS OF USE (THESE “TERMS”) SET FORTH THE LEGALLY BINDING TERMS AND
            CONDITIONS THAT GOVERN YOUR USE OF THE SITE. BY ACCESSING OR USING
            THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR
            THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT
            YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE
            TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU
            MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT
            LEAST 18 YEARS OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS
            OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.{'"'}
          </p>
          <p>
            Be advised that this Agreement contains disclaimers of warranties
            and limitations on liability that may be applicable to you.
          </p>
          <h2>
            USERS AND PURCHASERS OF MAINFRAME TOKENS MUST BE MADE AWARE OF
            CERTAIN ILLUSTRATIVE RISK FACTORS AS SET OUT IN APPENDIX A.
          </h2>
          <p>
            <strong>
              Notice Regarding Dispute Resolution: This Agreement contains
              provisions that govern how claims you and the Company have against
              each other are resolved (see Section 7 ( Limitation of Liability
              ), Section 10 ( Dispute Resolution ), and Section 11 ( Choice of
              Law and Forum ) below). It also contains an agreement to
              arbitrate, which will, with limited exception, require you to
              submit claims you have against us to binding and final
              arbitration, unless you opt-out of the agreement to arbitrate in
              accordance with Section 10(c). Unless you opt-out: (A) you will
              only be permitted to pursue claims against the Company on an
              individual basis, not as part of any class or representative
              action or proceeding and (B) you will only be permitted to seek
              relief (including monetary, injunctive, and declaratory relief) on
              an individual basis.
            </strong>
          </p>

          <h3>Changes to this Agreement</h3>

          <p>
            We reserve the right to change the terms and conditions of this
            Agreement by providing you with notice of such changes, such as by
            sending an email notification, providing notice through the Site, or
            updating the &ldquo;Last Updated&rdquo; date at the beginning of
            this Agreement. Any changes we make will be effective immediately
            upon providing notice to you. Your continued use of the Site will
            constitute your acceptance of any such updated Agreement terms. Be
            sure to return to this page periodically to ensure familiarity with
            the most current version of this Agreement.
          </p>

          <h3>Rules Governing Your Use of the SITE</h3>

          <p>
            The Site will provide you with information relating to our services
            and the use of Mainframe tokens.
          </p>
          <p>
            You represent and warrant that you are at least [21] years old and
            have the full right, power, and authority to enter into and perform
            this Agreement without the consent or approval of any third party.
          </p>
          <p>
            You may not use the Site, or assist or encourage any other party, to
            engage in any of the following prohibited activities:
          </p>
          <p>
            Violating any applicable law, statute, ordinance, or regulation, or
            encouraging any conduct that could constitute a criminal offense or
            give rise to civil liability;
          </p>
          <p>Copying, framing, or mirroring any part of the Site;</p>
          <p>Permitting any third party to access the Site;</p>
          <p>
            Publishing, transmitting, distributing, or storing content,
            material, information, or data that: (a) is illegal, obscene,
            defamatory, libelous, threatening, harassing, abusive, or hateful or
            that advocates violence or threatens the health of others; (b) is
            harmful to or interferes with the Site or any third party&rsquo;s
            networks, equipment, applications, services, or websites (e.g.,
            viruses, worms, Trojan horses, etc.); (c) infringes, dilutes,
            misappropriates, or otherwise violates any privacy, intellectual
            property, publicity, or other personal rights including, without
            limitation, copyrights, patents, trademarks, trade secrets, or other
            proprietary information (including unauthorized use of domain
            names); or (d) is fraudulent or contains false, deceptive, or
            misleading statements, claims, or representations (such as
            &ldquo;phishing&rdquo;);
          </p>
          <p>
            Attempting to disrupt, degrade, impair, or violate the integrity or
            security of the Site or the computers, services, accounts, or
            networks of any other party (including, without limitation,
            &ldquo;hacking,&rdquo; &ldquo;denial of service&rdquo; attacks,
            etc.), including any activity that typically precedes attempts to
            breach security such as scanning, probing, or other testing or
            vulnerability assessment activity, or engaging in or permitting any
            network or hosting activity that results in the blacklisting or
            other blockage of the Company internet protocol space;
          </p>
          <p>
            Collecting or harvesting any personally identifiable information,
            including account names, from the Site;
          </p>
          <p>
            Impersonating another person or otherwise misrepresenting your
            affiliation with a person or entity, conducting fraud, hiding or
            attempting to hide your identity;
          </p>
          <p>
            Submitting to the Site or to the Company any personally identifiable
            information, except as requested by the Company;
          </p>
          <p>
            Bypassing the measures we may use to prevent or restrict access to
            the Site;
          </p>
          <p>
            Transmitting any trade secret or other material, non-public
            information about any person, company, or entity without the
            authorization to do so;
          </p>
          <p>
            Removing any copyright, trademark, or other proprietary rights
            notices contained in or on the Site;
          </p>
          <p>
            Sublicensing, selling, renting, leasing, transferring, assigning, or
            conveying any rights under this Agreement to any third party, except
            as expressly permitted herein;
          </p>
          <p>
            Executing any form of network monitoring or running a network
            analyzer or packet sniffer or other technology to intercept, decode,
            mine, or display any packets used to communicate between the
            Company&rsquo;s servers or any data not intended for you; and/or
          </p>
          <p>
            Harvesting or collecting information about any Site visitors or
            members.
          </p>
          <p>
            Improper use of the Site may result in termination of your access to
            and use of the Site, and/or civil or criminal liabilities. You agree
            to use the Site in accordance with all applicable laws and
            regulations.
          </p>

          <h3>Intellectual Property</h3>

          <p>
            Your Rights. The Company grants you a limited, revocable right to
            access and use the Site solely for your own internal, non-commercial
            use. Use of the Site for any other purpose is strictly prohibited.
            You must not reproduce, distribute, modify, create derivative works
            of, publicly display, publicly perform, republish, download, store,
            or transmit any of the material on the Site. Except as expressly set
            forth herein, no rights or licenses are granted to you under this
            Agreement, whether by implication, estoppel, or otherwise.
          </p>
          <p>
            Company Rights. The Site and its contents are owned and operated by
            the Company and its licensors. The Site and its contents are
            protected by U.S. and international copyright, trademark, and other
            laws and will remain the property of the Company and its licensors.
            The Company reserves all rights not expressly granted herein in the
            Site. You acknowledge that you do not acquire any ownership rights
            by using the Site. The trademarks, logos, and service marks
            displayed on the Site (collectively, the &ldquo;Company
            Trademarks&rdquo;) are the registered and unregistered trademarks of
            the Company or the Company&rsquo;s licensors and suppliers. Nothing
            contained in this Agreement or the Site should be construed as
            granting, by implication, estoppel, or otherwise, any license or
            right to use any Company Trademarks without the express written
            permission of the Company or the Company&rsquo;s licensors.
          </p>

          <h3>PRIVACY</h3>

          <p>
            Please refer to our Privacy Policy at mainframe.com/legal/privacy
            for information about how we collect, use and share your
            information.
          </p>

          <h3>Term &amp; Termination</h3>

          <p>
            This Agreement is effective from the date on which you first access
            the Site and shall remain effective until terminated in accordance
            with its terms. The Company may immediately terminate this
            Agreement, and/or your access to and use of the Site, or any portion
            thereof, at any time and for any reason, with or without cause, by
            providing notice to you. The Company may also terminate this
            Agreement immediately if you fail to comply with any term or
            provision of this Agreement. Upon termination of this Agreement by
            either party, your right to access and use the Site shall
            immediately cease and you shall cease all access and use of the
            Site.
          </p>
          <p>
            Upon termination or expiration of this Agreement for any reason,
            Sections 1, 2, 3.2, and 4&ndash;12 shall survive.
          </p>
          <p>
            We reserve the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the Site, or any part or
            portion thereof, by providing notice to you. You agree that we shall
            not be liable to you or to any third party for any modification,
            suspension, or discontinuance of the Site, or any part or portion
            thereof. Nothing in this Agreement shall be construed to obligate
            the Company to maintain and support the Site, or any part or portion
            thereof, during the term of this Agreement.
          </p>

          <h3>Disclaimers</h3>

          <p>
            THE SITE IS PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;WITH ALL
            FAULTS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
            IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
            LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY,
            COMPLETENESS, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ANY
            WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF
            PERFORMANCE, OR USAGE OF TRADE, AND ANY WARRANTIES THAT THE SITE IS
            CURRENT AND/OR UP-TO-DATE ARE HEREBY EXPRESSLY DISCLAIMED TO THE
            FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW.
          </p>
          <p>
            THERE IS NO WARRANTY, REPRESENTATION, OR GUARANTEE THAT THE SITE, OR
            YOUR USE OF THE SITE, WILL BE UNINTERRUPTED, COMPLETE, ACCURATE,
            CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY PROBLEMS WILL BE
            CORRECTED, OR THAT THE SITE, OR ANY INFORMATION, SOFTWARE, OR OTHER
            MATERIAL ACCESSIBLE FROM THE SITE, IS FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS. WE DO NOT WARRANT, GUARANTEE, OR MAKE ANY
            REPRESENTATION REGARDING THE USE OF, OR THE RESULTS OF THE USE OF,
            THE SITE AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF
            THE SITE, AND YOUR RELIANCE THEREON.
          </p>

          <h3>Limitation of Liability</h3>

          <p>
            NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
            REMEDY OF ANY KIND, NEITHER THE COMPANY NOR ANY OF ITS LICENSORS,
            SPONSORS, AGENTS, SUCCESSORS, OR ASSIGNS, NOR OUR OR THEIR
            DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, OR OTHER
            REPRESENTATIVES, ARE RESPONSIBLE OR LIABLE FOR ANY INCIDENTAL,
            CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER INDIRECT
            DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS, LOST
            SAVINGS, OR LOSS OF DATA) UNDER ANY CONTRACT, NEGLIGENCE, STRICT
            LIABILITY, OR OTHER THEORY ARISING OUT OF OR RELATING IN ANY MANNER
            TO YOUR USE, OR INABILITY TO USE, THE SITE, WHETHER OR NOT WE HAVE
            BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR LIABILITIES.
          </p>
          <p>
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
            WARRANTIES AND/OR LIABILITIES, SO CERTAIN OF THE ABOVE LIMITATIONS
            OR EXCLUSIONS MAY NOT APPLY TO YOU.
          </p>

          <h3>Indemnification</h3>

          <p>
            You agree to fully indemnify, defend (at the Company&rsquo;s
            request), and hold the Company, our licensors, suppliers, agents,
            successors, and assigns, and our and their directors, officers,
            employees, consultants, and other representatives (collectively, the
            &ldquo;Company Parties&rdquo;) harmless from and against any and all
            claims, damages, losses, costs (including reasonable
            attorneys&rsquo; fees), and other expenses that arise directly or
            indirectly out of or from: (a) your actual or alleged breach of this
            Agreement; (b) any allegation that any materials you submit to us or
            transmit to the Site infringe, misappropriate, or otherwise violate
            the copyright, patent, trademark, trade secret, or other
            intellectual property or other rights of any third party; (c) your
            activities in connection with the Site; (d) your negligence or
            willful misconduct; (e) your use of the results, content, data, or
            information provided via the Site; (f) any service or product
            offered by you in connection with or related to your use of the
            Site; and/or (g) your violation of any laws, rules, regulations,
            codes, statutes, ordinances, or orders of any governmental and
            quasi-governmental authorities, including, without limitation, all
            regulatory, administrative and legislative authorities.
          </p>

          <h3>Jurisdictional Issues</h3>

          <p>
            The Company makes no representation that the Site operates (or is
            legally permitted to operate) in all geographic areas, or that the
            Site is appropriate or available for use in other locations.
            Accessing the Site from territories where the Site or any content or
            functionality of the Site or portion thereof is illegal is expressly
            prohibited. If you choose to access the Site, you agree and
            acknowledge that you do so on your own initiative and at your own
            risk, and that you are solely responsible for compliance with all
            applicable laws. [The Site is operated from the United States. If
            you are located outside of the United States and choose to use the
            Site, your information will be transferred, processed, and stored in
            the United States. U.S. privacy laws may not be as protective as
            those in your jurisdiction. Your agreement to the terms of this
            Agreement followed by your submission of your information in
            connection with the Site represents your agreement to this practice.
            If you do not want your information transferred to or processed or
            stored in the United States, you should not access or use the Site.]
          </p>

          <h3>Dispute Resolution</h3>

          <p>
            Arbitration. The parties shall use their best efforts to settle any
            dispute, claim, question, or disagreement directly through
            good-faith negotiations, which shall be a precondition to either
            party initiating a lawsuit or arbitration. Except for disputes
            relating to the Company&rsquo;s intellectual property (such as
            trademarks, trade dress, domain names, trade secrets, copyrights,
            and patents) or if you opt out of this agreement to arbitrate, all
            claims arising out of or relating to this Agreement and your access
            and use of the Site shall be finally settled by binding arbitration
            administered by the American Arbitration Association
            (&ldquo;AAA&rdquo;) in accordance with the provisions of its
            Commercial Arbitration Rules and of its supplementary procedures for
            consumer-related disputes, excluding any rules or procedures
            governing or permitting class actions. The arbitrator, and not any
            court or agency, shall have exclusive authority to resolve all
            disputes arising out of or relating to this Agreement, including,
            but not limited to, any claim that all or any part of this Agreement
            is void or voidable. The arbitrator shall be empowered to grant
            whatever relief would be available in a court; provided, however,
            that the arbitrator will not have authority to award damages,
            remedies, or awards that conflict with this Agreement. The
            arbitrator&rsquo;s award shall be binding on the parties and may be
            entered as a judgment in any court of competent jurisdiction. To the
            extent the filing fee for the arbitration exceeds the cost of filing
            a lawsuit, the Company will pay the additional cost. You and the
            Company hereby expressly waive trial by jury. You also agree not to
            participate in claims brought in a private attorney general or
            representative capacity, or consolidated claims involving another
            person&rsquo;s account, if the Company is a party to the proceeding.
            This dispute resolution provision will be governed by the Federal
            Arbitration Act.
          </p>
          <p>
            The parties understand that, absent this mandatory provision, they
            would have the right to sue in court and have a jury trial. They
            further understand that, in some instances, the costs of arbitration
            could exceed the costs of litigation and that the right to discovery
            may be more limited in arbitration than in court.
          </p>
          <p>
            Class-Action Waiver. The parties further agree that any arbitration
            shall be conducted in their individual capacities only, and not as a
            class action or other representative action. If any court or
            arbitrator determines that the class-action waiver set forth in this
            section is void or unenforceable for any reason, or that an
            arbitration can proceed on a class basis, then the arbitration
            provision set forth above shall be deemed null and void in its
            entirety and the parties shall be deemed not to have agreed to
            arbitrate disputes.
          </p>
          <p>
            Exception&mdash;Litigation of Small-Claims-Court Claims.
            Notwithstanding the parties&rsquo; decision to resolve all disputes
            through arbitration, either party may also seek relief in a small
            claims court for disputes or claims within the scope of that
            court&rsquo;s jurisdiction.
          </p>
          <p>
            Thirty-Day Right to Opt Out. You have the right to opt out and not
            be bound by the arbitration- and class-action-waiver provisions set
            forth in this section by sending written notice of your decision to
            opt out to the following email address:{' '}
            <a href="mailto:legal@mainframe.com">legal@mainframe.com</a>. The
            notice must be sent within 30 days after first accessing the Site
            and shall be deemed given 24 hours after it is sent. If you opt out
            of these arbitration provisions, the Company also will not be bound
            by them. In addition, if you elect to opt out of these arbitration
            provisions, the Company may prohibit you from accessing or using the
            Site.
          </p>
          <p>
            Time Limitation on Claims. You agree that any claim you may have
            arising out of or related to your relationship with the Company and
            this Agreement must be filed within one year after such claim arose;
            otherwise, your claim is permanently barred.
          </p>

          <h3>Choice of Law and Forum</h3>

          <p>
            This Agreement and your relationship with the Company Parties shall
            be governed by, and construed and interpreted in accordance with,
            the laws of the State of California without regard to its conflict
            of laws principles. Subject to Section 10, the parties irrevocably
            consent to bring any action to enforce this Agreement in the federal
            or state courts located in Utah County, Utah and you consent to the
            exclusive jurisdiction of the federal or state courts located in
            Utah County, Utah.
          </p>

          <h3>Miscellaneous</h3>

          <p>
            If any provision of this Agreement is held to be invalid or
            unenforceable by a court of competent jurisdiction, then the
            remaining provisions will nevertheless remain in full force and
            effect, and such provision will be reformed in a manner to
            effectuate the original intent of the parties as closely as possible
            and remain enforceable. If such reformation is not possible in a
            manner that is enforceable, then such term will be severed from the
            remaining terms, and the remaining terms will remain in effect. This
            is the entire Agreement between you and us relating to the subject
            matter herein and supersedes any and all prior or contemporaneous
            written or oral agreements between you and us with respect to such
            subject matter. This Agreement may not be changed, waived, or
            modified except by a written instrument signed by the Company. If
            any employee of the Company offers to modify this Agreement, he or
            she is not acting as an agent for the Company or speaking on the
            Company&rsquo;s behalf. You may not rely, and should not act in
            reliance on, any statement or communication from an employee of the
            Company or anyone else purporting to act on the Company&rsquo;s
            behalf. This Agreement is between you and the Company; there are no
            third-party beneficiaries. You may not access the Site if you are a
            resident of a country embargoed by the United States, or are a
            foreign person or entity blocked or denied by the United States
            government. No agency, partnership, joint venture,
            employee-employer, or franchiser-franchisee relationship is intended
            or created by this Agreement. Neither this Agreement nor any right,
            obligation, or remedy hereunder is assignable, transferable,
            delegable, or sublicensable by you except with the Company&rsquo;s
            prior written consent, and any attempted assignment, transfer,
            delegation, or sublicense shall be null and void. The Company may
            assign, transfer, or delegate this Agreement or any right or
            obligation or remedy hereunder in its sole discretion. No waiver by
            either party of any breach or default hereunder shall be deemed to
            be a waiver of any preceding or subsequent breach or default. Except
            as explicitly stated otherwise, legal notices shall be served on the
            Company at{' '}
            <a href="mailto:legal@mainframe.com">legal@mainframe.com</a>. Notice
            to you shall be deemed given 24 hours after the e-mail is sent. Any
            heading, caption, or section title contained in this Agreement is
            inserted only as a matter of convenience, and in no way defines or
            explains any section or provision hereof.
          </p>
          <h2>The Site is Operated By</h2>
          <p>
            Mainframe Group Inc.
            <br />
            Effective _____________, 2018
          </p>

          <h1>APPENDIX A</h1>
          <h2>
            CERTAIN RISK FACTORS ASSOCIATED WITH MAINFRAME TOKENS
            (&ldquo;MFT&rdquo;)
          </h2>

          <h3>
            <strong>
              <em>
                Risk of Losing Access to MFT tokens As a Result of Loss of
                Private Key(s), Custodial Error or Purchaser Error
              </em>
            </strong>
          </h3>
          <p>
            A private key, or a combination of private keys, is necessary to
            access a digital wallet or vault containing a purchaser&rsquo;s MFT
            tokens. As a result, such private key(s) are necessary in order to
            control, sell and otherwise dispose of the MFT tokens stored in such
            digital wallet or vault. The loss of the private key(s) needed to
            access such digital wallet or vault containing a purchaser&rsquo;s
            MFT tokens will result in such purchaser&rsquo;s effective loss of
            such MFT tokens. Furthermore, any third-party that gains access to
            such private key(s), including by gaining access to login
            credentials of a digital wallet or vault service that a purchaser
            uses, may be able to misappropriate a purchaser&rsquo;s MFT tokens.
            Any errors or malfunctions with respect to the digital wallet or
            vault a purchaser uses to receive and store MFT tokens, including
            such purchaser&rsquo;s failure to properly maintain or use such
            digital wallet or vault, could also result in such purchaser&rsquo;s
            effective loss of MFT tokens. Additionally, a purchaser&rsquo;s
            failure to precisely follow the procedures for buying and receiving
            MFT tokens, including, for instance, providing the wrong wallet or
            vault address for receiving MFT tokens, could result in such
            purchaser&rsquo;s effective loss of MFT tokens.
          </p>

          <h3>
            <strong>
              <em>Risks Associated with the Ethereum Protocol </em>
            </strong>
          </h3>
          <p>
            MFT tokens are based on the Ethereum protocol. As such, any
            malfunction, unintended function, unexpected functioning, forking,
            breakdown or abandonment in the Ethereum protocol, or any attack on
            the Ethereum protocol, could cause MFT tokens to malfunction or
            function in an unexpected or unintended manner, including, but not
            limited to, impacting your ability to transfer or securely hold MFT
            tokens. Such impact could adversely affect the value of MFT tokens.
            Ether, the native token of the Ethereum protocol, could itself
            decline in value, which could negatively affect the functioning of
            MFT tokens. Furthermore, advances in cryptography or other technical
            advances, such as the development of quantum computing, could
            present risks to MFT tokens by rendering ineffective the
            cryptographic consensus mechanism that underpins the Ethereum
            protocol. For more information on the Ethereum protocol, see
            http://www.ethereum.org.
          </p>

          <h3>
            <strong>
              <em>
                Risk of a Decrease in the Value of Bitcoin, Ether and Other
                Currencies
              </em>
            </strong>
          </h3>
          <p>
            The Mainframe Group intends to use the proceeds from the sale of MFT
            tokens to fund development of the Mainframe platform as discussed in
            the whitepaper. The proceeds of the sale of MFT tokens will be
            received/held in USD, bitcoin and ether, and may, at the Mainframe
            Group&rsquo;s discretion, be converted into other cryptographic or
            fiat currencies. If the value of USD, bitcoin, ether or other
            currencies decreases during or after the token sale, the value of
            proceeds received by the Mainframe Group will decrease and the
            Mainframe Group may not be able to fund development on the intended
            scale, or at all.
          </p>

          <h3>
            <strong>
              <em>Risk of Hacking and Security Weaknesses</em>
            </strong>
          </h3>
          <p>
            Hackers and other malicious groups or organizations may attempt to
            interfere with the functioning and ownership of MFT tokens in a
            variety of ways, including, but not limited to, undertaking malware
            attacks, denial of service attacks, consensus-based attacks, Sybil
            attacks, smurfing and spoofing. Furthermore, because MFT tokens are
            based on open-source software, there is a risk that a third-party or
            a member of the Mainframe Group team could intentionally or
            unintentionally introduce weaknesses into MFT tokens which could in
            turn be exploited by such groups. It may be difficult for the
            Mainframe Group to maintain or develop MFT tokens to address
            emerging issues or malicious programs that develop adequately or in
            a timely manner, and Mainframe Group may not have adequate resources
            to do so. Such events could result in a loss of trust in the
            security and operation of MFT tokens, which could materially
            adversely affect the value of MFT tokens and the ownership thereof.
          </p>

          <h3>
            <strong>
              <em>
                Risk of Theft of Funds Raised in the Token Generation Event{' '}
              </em>
            </strong>
          </h3>
          <p>
            The Mainframe Group will make every effort to ensure that the funds
            received from the MFT token sale will be securely held.
            Notwithstanding such security measures, there is no assurance that
            there will be no theft of the cryptocurrencies as a result of hacks,
            sophisticated cyber-attacks, distributed denials of service or
            errors, vulnerabilities or defects on the Mainframe Group website,
            in the smart contract(s) on which the escrow wallet and the MFT
            token sale relies, on Ethereum or any other blockchain, or
            otherwise.
          </p>
          <p>
            Such events may include, for example, flaws in programming or source
            code leading to exploitation or abuse thereof. In such event, even
            if the MFT token sale is completed, the Mainframe Group may not be
            able to receive the cryptocurrencies raised. In such case, the
            Mainframe platform and any associated plans might be temporarily or
            permanently curtailed. As such, distributed MFT tokens may hold
            little worth or value.
          </p>

          <h3>
            <strong>
              <em>Risk of Mining Attacks</em>
            </strong>
          </h3>
          <p>
            As with other decentralized cryptographic tokens and
            cryptocurrencies, the blockchain used for the MFT token application
            is susceptible to mining attacks, including, but not limited to,
            double-spend attacks, majority mining power attacks, selfish-mining
            attacks and race condition attacks. Any successful attacks present a
            risk to MFT tokens and holders thereof, and to the expected proper
            execution and sequencing of Ethereum contract computations. Despite
            the efforts of the Mainframe Group, the risk of known or novel
            mining attacks persists, and the Mainframe Group may not be able to
            protect against such attacks. If successful, such attacks could
            materially adversely affect the value of MFT tokens and the
            ownership thereof.
          </p>

          <h3>
            <strong>
              <em>
                Risk of Unfavorable Regulatory Intervention in One or More
                Jurisdictions{' '}
              </em>
            </strong>
          </h3>
          <p>
            Blockchain technologies and cryptocurrencies are relatively recent
            technologies which have developed rapidly and have been rolled out
            into numerous applications. This evolution has presented novel
            issues in terms of the classification, regulation and control of
            these technologies. As a result, it is often unclear what, if any,
            regulatory regime is applicable to blockchain technologies and
            cryptocurrencies.
          </p>
          <p>
            As the popularity of blockchain technologies and cryptocurrencies,
            as well as the aggregate capitalization of cryptocurrencies, have
            grown, blockchain technologies have come under the scrutiny of
            various regulatory bodies around the world, and U.S. federal and
            state agencies have begun to take interest in, and in some cases
            initiated efforts to regulate, their use and operation.
          </p>
          <p>
            In light of this evolving technological and legal landscape, the
            regulation of tokens (including MFT tokens), token offerings,
            cryptocurrencies, blockchain technologies, and cryptocurrency
            exchanges is likely to continue to evolve rapidly, to vary
            significantly among international, U.S. federal, state and local
            jurisdictions and regulatory bodies, and to be subject to
            significant uncertainty. Going forward, various legislative,
            executive and regulatory bodies in the United States and in other
            countries could adopt laws, regulations or guidance, or take other
            actions, which could materially negatively impact the functioning,
            use or legality of MFT tokens. For example, regulatory inquiries or
            actions, including the imposition of licensing requirements or
            restrictions on the use, sale, or possession of digital tokens such
            as MFT tokens, could impede, limit or terminate the development of
            the tokens.
          </p>
          <p>
            Furthermore, any failure by the Mainframe Group, purchasers of MFT
            tokens or related parties to comply with any laws, rules, and
            regulations, some of which may not yet exist yet or may be subject
            to uncertain or varying interpretation, and which may be subject to
            change, could result in a variety of adverse consequences for such
            parties, including civil penalties and fines.
          </p>
          <p>
            At the state level in the United States, some state regulators such
            as the New York Department of Financial Services have created new
            regulatory frameworks to directly address cryptocurrencies. Some
            other state regulators, such as in Texas, have published guidance on
            how such states&rsquo; existing regulatory regimes apply to
            cryptocurrencies. Yet other states, such as New Hampshire, North
            Carolina and Washington, have amended their state statutes to
            explicitly apply existing licensing regimes to cryptocurrencies.
          </p>
          <p>
            At the federal level in the United States, the treatment of
            cryptocurrencies under the law continues to evolve as well. The
            Department of the Treasury, the Securities Exchange Commission (the
            &ldquo;SEC&rdquo;) and the Commodity Futures Trading Commission (the
            &ldquo;CFTC&rdquo;) have all published guidance on the treatment of
            cryptocurrencies. The Internal Revenue Service released guidance
            treating cryptocurrencies as property that is not currency for U.S.
            federal income tax purposes, although there is no indication yet
            whether courts or other U.S. federal or state regulators will follow
            this classification. Various U.S. federal and state agencies have
            instituted enforcement actions against those violating their
            interpretation of existing laws.
          </p>
          <p>
            The regulation of the use of blockchain assets other than as
            currency is also uncertain. The CFTC has publicly taken the position
            that certain blockchain assets are commodities, and the SEC has
            issued statements, including a public report and a cease-and-desist
            order, stating that U.S. federal securities laws require treating
            some non-currency blockchain assets as securities. To the extent
            that a domestic governmental or quasi-governmental agency exerts
            regulatory authority over blockchain networks or assets, such
            regulation could extend to MFT tokens, which could have a material
            adverse effect on the Mainframe Group, the value of the tokens and
            holders of tokens.
          </p>
          <p>
            In other countries, the regulatory landscape of blockchain
            technologies and cryptocurrencies is also uncertain and varied.
            Various non-U.S. jurisdictions could, in the near future, adopt
            laws, regulations or directives that affect MFT tokens. Such laws,
            regulations or directives may conflict with those of the United
            States or may directly and negatively impact the Mainframe
            Group&rsquo;s business. The effect of any future regulatory change
            is impossible to predict, and could have a material adverse effect
            on the Mainframe Group, the value of the tokens and holders tokens.
          </p>
          <p>
            New or changing laws and regulations, or interpretations of existing
            laws and regulations, in the United States and other jurisdictions,
            could also materially adversely affect the value of currencies into
            which MFT tokens may be exchanged, the liquidity of the tokens, the
            ability to access marketplaces or exchanges to trade the tokens and
            the structure, use and transferability of the tokens. The Mainframe
            Group may choose to cease operations in a particular jurisdiction in
            the event that regulatory actions, or changes to law or regulation,
            make it illegal to operate in such jurisdiction, or commercially
            undesirable to obtain the necessary regulatory approvals to operate
            in such jurisdiction.
          </p>

          <h3>
            <strong>
              <em>
                Risks Inherent in the Interoperable Nature of Cryptographic
                Tokens{' '}
              </em>
            </strong>
          </h3>
          <p>
            Cryptographic tokens, especially those built on the ERC-20 standard,
            such as MFT tokens, are generally tradable on exchanges or through
            direct peer-to-peer trading protocols that are not currently
            regulated by any governmental authority in a manner that traditional
            stock or equity interests are regulated. Therefore, purchasers of
            MFT tokens are not afforded any of the protections of laws or
            regulations that purchasers of traditional shares or equity would
            enjoy. Additionally, the Mainframe Group has included transfer
            restrictions as a condition for the purchase of any MFT tokens, but,
            due to the interoperability of ERC-20 tokens, such as MFT tokens,
            the Mainframe Group may not be able to effectively enforce such
            restrictions and may be unable to prevent the negative consequences
            of unauthorized MFT token transfers and potentially subject the
            Mainframe Group to regulatory fines, actions or registration
            requirements.
          </p>

          <h3>
            <strong>
              <em>Risk that a Liquid Market for MFT Tokens May not Develop</em>
            </strong>
          </h3>
          <p>
            MFT tokens are not listed on any cryptocurrency exchange. The
            Mainframe Group may seek to list the tokens on one or more
            cryptocurrency exchanges, but there is no guarantee that such
            listing will be successful or will be maintained. Furthermore, even
            if such approval is granted by a cryptocurrency exchange, there is
            no assurance that an active or liquid trading market for MFT tokens
            will develop, or if developed, will be sustained after the MFT
            tokens have been made available for trading on such cryptocurrency
            exchange. There is also no assurance that the market price of the
            MFT tokens will not decline below the original purchase price. The
            purchase price may not be indicative of the market price of MFT
            tokens after they have been made available for trading on a
            cryptocurrency exchange. Any expectation that the value of the MFT
            tokens will increase is purely speculative.
          </p>
          <p>
            MFT tokens are not currency issued by any central bank or national,
            supra-national or quasi-national organization, nor are they backed
            by any hard assets or other credit. The Mainframe Group is not
            responsible for, [nor does it pursue], the circulation and trading
            of MFT tokens on the market. Trading of MFT tokens will merely
            depend on the consensus on its value between the relevant market
            participants. No one is obliged to purchase any MFT tokens from any
            holder of the MFT tokens, including the purchasers, nor does anyone
            guarantee the liquidity or market price of MFT tokens to any extent
            at any time.
          </p>
          <p>
            Furthermore, MFT tokens may not be freely resold unless pursuant to
            an applicable exemption to purchasers who are citizens or permanent
            residents of the People&rsquo;s Republic of China, Singapore, the
            United States or any other jurisdiction where the purchase of MFT
            tokens may be in violation of applicable laws. Accordingly, the
            Mainframe Group cannot ensure that there will be any demand or
            market for MFT tokens, or that the purchase price is indicative of
            the market price of MFT tokens after they have been made available
            for trading on a cryptocurrency exchange.
          </p>

          <h3>
            <strong>
              <em>Risks Associated with Cryptocurrency Exchanges</em>
            </strong>
          </h3>
          <p>
            Cryptocurrency exchanges on which MFT tokens may trade may be
            relatively new and largely unregulated and may therefore be more
            exposed to fraud and failure than established regulated exchanges.
            To the extent that the cryptocurrency exchanges representing a
            substantial portion of the volume in MFT token trading are involved
            in fraud or experience security failures or other operational
            issues, such cryptocurrency exchange failures may result in a
            reduction in the price and can adversely affect the value of the
            tokens. A lack of stability in cryptocurrency exchanges and the
            closure or temporary shutdown of cryptocurrency exchanges due to
            fraud, business failure, hackers or malware, or government-mandated
            regulation may result in greater volatility in the price of the
            tokens.
          </p>

          <h3>
            <strong>
              <em>Risks Related to Future Sales or Issuance of MFT tokens </em>
            </strong>
          </h3>
          <p>
            Any future sale or issuance of the MFT tokens would increase the
            supply of MFT tokens in the market and this may result in a downward
            price pressure on the MFT tokens. The sale or distribution of a
            significant number of MFT tokens outside of the token generation
            event (including but not limited to the sales of MFT tokens
            undertaken after the completion of the pre-sale and issuance of MFT
            tokens to persons other than purchasers for purposes outlined in the
            whitepaper), or the perception that such further sales or issuance
            may occur, could adversely affect the trading price of MFT tokens.
          </p>

          <h3>
            <strong>
              <em>Risks Related to Token Taxation</em>
            </strong>
          </h3>
          <p>
            The tax characterization of MFT tokens in the United States and
            elsewhere is uncertain. Purchasers of the tokens must seek their own
            tax advice in connection with purchasing, holding and selling the
            tokens, which may result in adverse tax consequences, including
            withholding taxes, increased income tax liability and additional tax
            reporting requirements. Purchasers of the tokens should consult with
            and must rely upon the advice of their own professional tax advisors
            with respect to the U.S. and non-U.S. tax treatment of their
            receipt, purchase, holding and sale of the tokens.
          </p>

          <h3>
            <strong>
              <em>Risk of Negative Publicity</em>
            </strong>
          </h3>
          <p>
            Negative publicity involving the Mainframe Group, MFT tokens, or any
            of the key personnel of the Mainframe Group, regulation of
            cryptocurrencies in the U.S. or worldwide, may materially and
            adversely affect the market perception or market price of the MFT
            token, whether or not it is justified.
          </p>

          <h3>
            <strong>
              <em>Risk of Personal Information Disclosure</em>
            </strong>
          </h3>
          <p>
            The Mainframe Group may determine, in its sole discretion, that it
            is necessary to obtain certain information about a purchaser of MFT
            tokens in order to comply with applicable law or regulation in
            connection with selling MFT tokens to such purchaser. A purchaser
            may be asked to provide such information upon request, and the
            Mainframe Group may refuse to sell MFT tokens to such purchaser
            until they provide the requested information and the Mainframe Group
            has determined that it is permissible to sell MFT tokens to them
            under applicable law or regulation. The Mainframe Group will take
            reasonable measures to ensure that any such disclosed personal
            information is maintained confidentially and/or destroyed as
            applicable, but where such information is transmitted or stored
            electronically, there is a risk of its unintentional disclosure to
            third parties as a result of hacking, accidental disclosure or
            disclosure demanded by governmental authorities.
          </p>

          <h3>
            <strong>
              <em>Risk of Uninsured Losses</em>
            </strong>
          </h3>
          <p>
            Unlike bank accounts or accounts at certain other financial
            institutions, MFT tokens are uninsured as a store of value unless a
            purchaser specifically obtains private insurance to insure them.
            Thus, in the event of loss or loss of value of MFT tokens, there is
            no public insurer, such as the Federal Deposit Insurance
            Corporation, or private insurance arranged by the Mainframe Group,
            to offer recourse to a purchaser of MFT tokens.
          </p>

          <h3>
            <strong>
              <em>Unanticipated Risks</em>
            </strong>
          </h3>
          <p>
            Cryptographic tokens are a new and untested technology. In addition
            to the risks discussed here there are risks that the Mainframe Group
            cannot anticipate. Further risks may materialize as unanticipated
            combinations or variations of the aforementioned risks, or yet
            unforeseen risks, develop. Such risks could have unanticipated
            consequences, including a material adverse impact on the value of
            MFT tokens or the ownership thereof.
          </p>
        </div>
      </Layout>
    )
  }
}
